export const frag111 =
  `
varying vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform vec4 filterArea;
uniform vec4 textureArea;

vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec2 mapCoord(vec2 coord)
    {
        // 注意这里的 xy 是 [width, height]
        coord *= filterArea.xy;
        coord += (filterArea.zw - textureArea.zw);

        return coord;
    }

vec2 unmapCoord(vec2 coord)
{
    coord -= (filterArea.zw - textureArea.zw);
    coord /= filterArea.xy;

    return coord;
}

void main () {
  vec2 v = vec2(vTextureCoord.xy);
  v.x = v.x / 2.0;
  vec2 mask = vec2(vTextureCoord.x + 0.5, v.y);

  vec3 hsv = rgb2hsv(texture2D(uSampler, mask).rgb);
  if (hsv.z < 0.5) {
    hsv.z = 0.0;
  }
  vec4 color = vec4(texture2D(uSampler, vTextureCoord.xy).rgb, hsv.z);
  if (vTextureCoord.x > 0.5) {
    color = vec4(0.0, 0.0, 0.0, 0.0);
  } else {
    gl_FragColor = color;
  }

//   gl_FragColor = texture2D(uSampler, vTextureCoord);
}
`


export const frag =
  `
#ifdef GL_ES
precision mediump float;
#endif

uniform sampler2D uSampler;
uniform vec2 u_resolution;


vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

void main () {
  vec2 uv = gl_FragCoord.xy / u_resolution.xy;
  vec2 mask = vec2(uv.x + 0.5, uv.y);

  vec3 hsv = rgb2hsv(texture2D(uSampler, mask).rgb);

  vec4 color = vec4(texture2D(uSampler, uv.xy).rgb, hsv.z);
  if (uv.x > 0.5) {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  } else {
    gl_FragColor = color;
  }

}
`