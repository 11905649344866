function reconnectWs(props) {
  console.warn('重新连接ws')
  const { lock } = props;
  if (lock.lockReconnect) {
    return;
  };
  lock.lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  lock.tt && clearTimeout(lock.tt);
  lock.tt = setTimeout(function () {
    createWebSocket(props);
    lock.lockReconnect = false;
  }, 4000);
}
function heartCheck(timer) {
  // const self = this;
  const timeout = 15000;
  const serverTimeout = 20000;

  return {
    serverTimeoutObj: null,
    start: function (vhostToken, apiWs) {
      if (!apiWs) return;
      timer.checkTimer && clearTimeout(timer.checkTimer);
      timer.serverTimer && clearTimeout(timer.serverTimer);
      timer.checkTimer = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        apiWs.send(JSON.stringify({
          vhostToken, // 用户认证
          uuid: "heartCheck", // 唯一，不同消息区分
          msgType: 11
        }));
        timer.serverTimer = setTimeout(function () {
          console.error("ws响应超时")
          apiWs.close();
        }, serverTimeout);
      }, timeout)
    },
    stop: function () {
      timer.checkTimer && clearTimeout(timer.checkTimer);
      timer.serverTimer && clearTimeout(timer.serverTimer);
    }
  }

}
function createWebSocket(props) {
  const lock = {
    tt: undefined,
    lockReconnect: false,//避免重复连接
  }
  const { url, instance } = props;
  instance.wsInstance = new WebSocket(url);
  // console.log('instance', instance);
  initWs({ lock, instance, ...props });

}
function initWs(props) {
  const { instance, streamId, vhostToken, onMessage, streamType, event } = props;
  let timer = {
    checkTimer: null,
    serverTimer: null,
  };
  const heart = heartCheck(timer);
  // console.log("apiWs", apiWs);
  instance.wsInstance.onclose = function (e) {
    console.warn('ws链接关闭', e);
    event('wsDisconnect', e);
    heart.stop();
    reconnectWs(props);
  };
  instance.wsInstance.onerror = function (e) {
    console.error('ws发生异常了', e);
    event('wsDisconnect', e);
    heart.stop();
    reconnectWs(props);
  };
  instance.wsInstance.onopen = function () {
    instance.wsInstance.send(JSON.stringify({
      vhostToken, // 用户认证
      uuid: 'streamStart', // 唯一，不同消息区分
      msgType: 12,
      data: {
        data: {
          message_type: 'start',
          stream_id: streamId,
          stream_type: streamType,
          iefa: getId(),
          resolution: {
            width: document.body.clientWidth,
            height: document.body.clientHeight
          },
        }
      }
    }));
  };
  instance.wsInstance.onmessage = function (e) {
    //拿到任何消息都说明当前连接是正常的
    heart.start(vhostToken, instance.wsInstance);
    let d = e.data;
    if (!d) return;
    d = JSON.parse(d);
    onMessage(d);
  }
  // apiWs.send = send;
}

function getId() {
  let id = localStorage.getItem('rtcRandomId');
  if (!id) {
    id = Math.random() * Math.pow(10, 20);
    localStorage.setItem('rtcRandomId', id);
  }
  return id.toString();
}

export {
  createWebSocket,
}