

import { recStart, recStop } from './rec/record-util';

function event(configData) {
  const listenerList = {
    "load": function () {
    },
    "error": function () {
    },
    "onRecord": function (start) {
      console.log('onRecord', start);
      if (!configData.isSupportAsr) return;
      const instance = configData.instance;
      if (start) {
        recStart(undefined, (data) => {
          const ready = instance.wsInstance && instance.wsInstance.readyState;
          if (ready === 1) { // 判断websocket是否关闭
            instance.wsInstance.send(data);
          }
        });
      } else {
        recStop();
      }
    },
    "asrResult": function (data) { },
    "answer": function (data) { },
    "wsDisconnect": function (data) { },
  }
  const ev = {
    dispatch: function (eventKey) {
      const args = Array.prototype.slice.call(arguments, 1);
      listenerList[eventKey].apply(this, args);
      return this;
    },
    listener: function (eventKey, callback) {
      if (typeof eventKey === "string" && typeof callback === "function") {
        listenerList[eventKey] = callback;
      }
      return this;
    }
  }
  return {
    emitter: ev.dispatch,
    listener: ev.listener,
  }
}

export default event;

