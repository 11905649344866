import JSEncrypt from 'jsencrypt';
import { postData, isSecurity } from './util';
// let loginUrl = 'https://test-vhost-entry.shuwen.com/v1/akLogin'; // 测试环境
// // let wsUrl = 'ws://test.api.shuwen.com/vhost-entry/v2/vhost/realtime/ws'; // 测试环境
// // let loginUrl = 'https://api.xinhuazhiyun.com/vhost-entry/v1/akLogin'; // 正式环境
// // let wsUrl = 'wss://api.xinhuazhiyun.com/vhost-entry/v2/vhost/realtime/ws'; // 正式环境
// // const streamId = "e33efd3a468a11ec893b9589d84c46e9";   
// // const ak = "9c57632b68444d4b9faf4739ff984161";

// if (process.env.NODE_ENV === 'production') {
//   loginUrl = 'https://api.xinhuazhiyun.com/vhost-entry/v1/akLogin'; // 正式环境
//   // wsUrl = 'wss://api.xinhuazhiyun.com/vhost-entry/v2/vhost/realtime/ws'; // 正式环境
// }

function setEncrypt(msg) {
  let key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCq9wjzLCQyt4Vb/HkNPAfbcJRnlnsbzeqtPNYCYR90XH4zaBIqGLIeaFNEfA/znPzjb0XgI3t4T/c0V3HYfY/vcHHxjhiuzwFoTd538UoKgMtQQYcW6pC59Jj3Nt0MTES4UT7+RsyoNF3ZPianLoS3GrocL37SXNQtiVgLkJN6BwIDAQAB"
  const jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(key)
  return jsencrypt.encrypt(msg)
}

export function getToken(loginInfo) {
  const { ak, username, password, endpoint } = loginInfo;
  let protocol = isSecurity() ? 'https:' : 'http:';
  const loginUrl = `${protocol}//${endpoint}/v1/akLogin`
  return postData(loginUrl, {
    ak,
    username,
    password: setEncrypt(password),
  })
}