/* eslint-disable */
let Recorder = require('./recorder');
// import SockJS from 'sockjs';
// var SockJS = require('sockjs');


Recorder = window.Recorder;

let canSend = true;
let testSampleRate = 16000; //输出采样率
let testBitRate = 16; // 输出采样位数
let SendInterval = 20;


//重置环境
let RealTimeSendTryReset = function (type) {
  realTimeSendTryType = type;
  realTimeSendTryTime = 0;
};

let realTimeSendTryType;
let realTimeSendTryEncBusy;
let realTimeSendTryTime = 0;
let realTimeSendTryNumber;
let transferUploadNumberMax;
let realTimeSendTryChunk;

//=====实时处理核心函数==========
let RealTimeSendTry = function (rec, isClose, cb) {
  // console.log('11111111111', rec)
  let t1 = Date.now(),
    endT = 0,
    recImpl = Recorder.prototype;
  if (realTimeSendTryTime === 0) {
    realTimeSendTryTime = t1;
    realTimeSendTryEncBusy = 0;
    realTimeSendTryNumber = 0;
    transferUploadNumberMax = 0;
    realTimeSendTryChunk = null;
  }
  if (!isClose && t1 - realTimeSendTryTime < SendInterval) {
    return; //控制缓冲达到指定间隔才进行传输
  }
  realTimeSendTryTime = t1;
  let number = ++realTimeSendTryNumber;

  //借用SampleData函数进行数据的连续处理，采样率转换是顺带的
  let chunk = Recorder.SampleData(rec.buffers, rec.srcSampleRate, testSampleRate, realTimeSendTryChunk, {
    frameType: isClose ? "" : realTimeSendTryType
  });
  if (canSend) {
    // console.log('发送了语音指令。。。。。。。。', chunk.data)
    // socket.send(chunk.data)
    cb(chunk.data);
  } else {
    // console.log('cansend', canSend)
  }
  //清理已处理完的缓冲数据，释放内存以支持长时间录音，最后完成录音时不能调用stop，因为数据已经被清掉了
  for (let i = realTimeSendTryChunk ? realTimeSendTryChunk.index : 0; i < chunk.index; i++) {
    rec.buffers[i] = null;
  }
  realTimeSendTryChunk = chunk;

  //没有新数据，或结束时的数据量太小，不能进行mock转码
  if (chunk.data.length === 0 || isClose && chunk.data.length < 2000) {
    // TransferUpload(number,null,0,null,isClose);
    return;
  }

  //实时编码队列阻塞处理
  if (!isClose) {
    if (realTimeSendTryEncBusy >= 2) {
      // console.log("编码队列阻塞，已丢弃一帧",1);
      return;
    }
  }
  realTimeSendTryEncBusy++;

  //通过mock方法实时转码成mp3、wav
  let encStartTime = Date.now();
  let recMock = Recorder({
    type: realTimeSendTryType,
    sampleRate: testSampleRate, //采样率 
    bitRate: testBitRate //比特率
  });
  recMock.mock(chunk.data, chunk.sampleRate);
  recMock.stop(function (blob, duration) {
    realTimeSendTryEncBusy && (realTimeSendTryEncBusy--);
    // console.log('ccccccccc', blob)
    blob.encTime = Date.now() - encStartTime;

    //转码好就推入传输
    TransferUpload(number, blob, duration, recMock, isClose);    //暂不用
  }, function (msg) {
    realTimeSendTryEncBusy && (realTimeSendTryEncBusy--);

    //转码错误？没想到什么时候会产生错误！
    // console.log("不应该出现的错误:"+msg,1);
  });
};

//=====数据传输函数==========
let TransferUpload = async function (number, blobOrNull, duration, blobRec, isClose) {
  transferUploadNumberMax = Math.max(transferUploadNumberMax, number);
  if (blobOrNull) {
    let blob = blobOrNull;
    let encTime = blob.encTime;

    let logMsg = "No." + (number < 100 ? ("000" + number).substr(-3) : number);
    // console.log(`${logMsg}, duration: ${duration}ms，花 ${encTime}ms`);
    // console.log('blob........', blob)
    if (canSend) {
      // console.log("send socket =======")
      // socket.send(blob)
      // console.log("=======")
    }
  }
  if (isClose) {
    // console.log("No."+(number<100?("000"+number).substr(-3):number)+":已停止传输");
  }
};

//调用录音
let rec;

function recStart(type = 'wav', cb) {
  // console.log('调用录音', window.Recorder)
  if (rec) {
    rec.close();
    // socket.send('stop');
  }
  // socket.send('start')
  rec = Recorder({
    type: "unknown",
    onProcess: function (buffers, powerLevel, bufferDuration, bufferSampleRate) {
      //推入实时处理，因为是unknown格式，这里简化函数调用，没有用到buffers和bufferSampleRate，因为这些数据和rec.buffers是完全相同的。
      RealTimeSendTry(rec, false, cb);
    }
  });

  let t = setTimeout(function () {
    // console.log("无法录音：权限请求被忽略",1);
  }, 8000);

  rec.open(function () { //打开麦克风授权获得相关资源
    clearTimeout(t);
    rec.start(); //开始录音

    RealTimeSendTryReset(type); //重置
  }, function (msg, isUserNotAllow) {
    clearTimeout(t);
    console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg, 1);
  });
}

function recStop() {
  console.log('关闭语音')
  if (rec) {
    rec.close(); //直接close掉即可，这个例子不需要获得最终的音频文件

  }
  // RealTimeSendTry(rec,true);//最后一次发送
}

export {
  recStart,
  recStop,
}
