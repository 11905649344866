function checkConfig({
  streamId,
  accessToken,
  dom
}) {
  let checkPass = true;
  if (!streamId) {
    console.error('缺少streamId');
    checkPass = false;
  }
  if (!accessToken) {
    console.error('缺少vhostToken');
    checkPass = false;
  }
  // dom 必须是dom节点，这边只是做了粗略判断
  if (dom && typeof dom !== 'object') {
    console.error('dom参数类型错误，必须是dom节点')
    checkPass = false;
  }
  return checkPass;
}

export {
  checkConfig,
}