import {
    setPixiShader,
    createTag,
    createMedia,
    handleSei,
    isSecurity,
    canRun,
    onlyOnce,
} from './util';
import { SrsRtcPlayerAsync } from './srsRtcPlayer';
import { createWebSocket } from './ws';
import { recStart, recStop } from './rec/record-util';
import { getToken } from './loginUtil';
import { checkConfig } from './check';
import Event from './event';
import { speak, destroy, autoPlayAudio, stopSepak, askAboutHuman } from './playerAction'

// let wsUrl = 'ws://test.vhost-entry.shuwen.com/v2/vhost/realtime/ws'; // 测试环境
// if (process.env.NODE_ENV === 'production') {
//     wsUrl = 'wss://api.xinhuazhiyun.com/vhost-entry/v2/vhost/realtime/ws'; // 正式环境
// }
let retries = 3;
var startPlay = function (webRtcUrl, videoNode, instance, event) {
    const eventOnce = onlyOnce(event, 500)
    let sdkInstance = instance.sdkInstance;
    if (sdkInstance) {
        sdkInstance.close(); // 如果有上一个连接没关闭，则关闭
    }
    //
    instance.sdkInstance = new SrsRtcPlayerAsync();
    sdkInstance = instance.sdkInstance;
    sdkInstance.onaddstream = function (event) {
        videoNode.srcObject = event;
    };
    sdkInstance.onseimsg = function (dataStr) {
        handleSei(dataStr, eventOnce);
    };
    sdkInstance.play(webRtcUrl).then(function (session) {
        retries = 3;
    }).catch(function (reason) {
        sdkInstance.close();
        console.error(reason);
        console.error(retries);
        retries-- > 0 && setTimeout(() => {
            console.log('play error, try again');
            startPlay(webRtcUrl, videoNode, instance, event)
        }, 5 * 1000);
    });
};

// 鉴权登录
function connectHost({ streamId, videoNode, instance, event, token, onAction, streamType, endpoint, configData }) {
    let protocol = isSecurity() ? 'wss:' : 'ws:';
    let wsUrl = `${protocol}//${endpoint}/v2/vhost/realtime/ws`; // 测试环境

    let webRtcUrl = null;
    // instance.wsInstance = 
    createWebSocket({
        streamId,
        vhostToken: token,
        url: `${wsUrl}?vhostToken=${token}`,
        streamType,
        instance,
        event,
        onMessage: function (d) {
            if (d.code === -1) {
                console.error(d.msg);
                event('error', { code: d.code, msg: d.msg, id: d.uuid });
            } else if (d.uuid === 'streamStart') {
                // 流开始，播放
                let url = d.data.stream_url;
                webRtcUrl = url;
                startPlay(url, videoNode, instance, event);
                event('load');
            } else if (d.uuid === 'connectionEstablished' && d.data.isSupportAsr) {
                // 权限校验，判断有没有语音能力, 开启语音识别
                configData.isSupportAsr = true;
            } else if ((d.data.action === 'running' || d.data.action === 'end') && d.data.sentence) {
                // 语音识别结果
                let text = d.data.sentence.text;
                console.log('语音识别结果', text);
                event('asrResult', { "asrtxt": text, "action": d.data.action });
            } else if (d.uuid == "answer" && d.data.answer) {
                // 对话结果
                console.log('对话返回：' + d.data.answer);
                event('answer', d.data.answer);
            } else if (typeof d.mediaType === 'string') {
                // 处理 语音识别后关键词配置的弹出图片、视频、文字、语音
                // d.mediaType 可能为空字符串
                onAction({
                    mediaType: d.mediaType,
                    mediaUrl: d.mediaUrl,
                });
            } else if ((d.uuid === 'heartCheck') && d.data.isRestart) {
                if (webRtcUrl) {
                    startPlay(webRtcUrl, videoNode, instance, event);
                    console.log('重新startPlay')
                    event('load');
                }
            }
        },
    });
}

function handleAction(dom, data) {
    if (!dom) {
        console.error('媒体弹出失败，缺少dom');
        return;
    }
    createMedia(dom, data);
}

// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
function RtcPlayer(props) {
    if (!canRun()) {
        alert('此浏览器存在兼容性问题，请下载新版chrome浏览器体验');
        return;
    }
    const { streamId, pos, size, dom, accessToken, mediaConfig, streamType, endpoint } = props;
    // 验证参数
    let checkPassed = checkConfig(props);
    if (!checkPassed) {
        return;
    }
    // 创建节点容器
    const { videoNode, canvasNode } = createTag(pos, size, dom);

    // ws实例、webrtc播放器实例
    const instance = {
        wsInstance: null,
        sdkInstance: null,
    }

    this.instance = instance;
    this.streamId = streamId;
    this.videoNode = videoNode;
    this.canvasNode = canvasNode;
    this.vhostToken = accessToken;
    const self = {};
    self.configData = {
        instance,
        isSupportAsr: false
    };
    // 初始化事件监听、触发
    const { listener: eventListener, emitter: eventEmitter } = Event(self.configData);
    // 建立ws连接，打开直播流
    connectHost({
        streamId,
        videoNode,
        instance,
        endpoint,
        event: eventEmitter,
        token: accessToken,
        streamType,
        onAction: (d) => handleAction(mediaConfig && mediaConfig.dom, d),
        configData: self.configData
    })

    // 抠图，去除绿幕
    setPixiShader(videoNode, canvasNode);

    // 任意点击播放声音，因为video标签不支持自动播放声音
    autoPlayAudio(videoNode);

    // const self = {};
    // 暴露监听事件
    self.on = eventListener;
    self.emit = eventEmitter;
    // 暴露sendSpeak接口
    self.sendSpeak = speak.bind(this);
    self.stopSpeak = stopSepak.bind(this);

    //传递问题
    self.askAbout = askAboutHuman.bind(this);
    // 暴露destroy接口
    self.destroy = destroy.bind(this);

    // 返回
    return self;
}

export {
    RtcPlayer,
    getToken,
};