// import * as PIXI from 'pixi.js';
import { Canvas } from 'glsl-canvas-js';
import { frag } from './frag';
export function postData(url, data) {
  // Default options are marked with *
  return fetch(url, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json'
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  })
    .then(response => response.json()) // parses response to JSON
}

export function setPixiShader(videoNode, canvasNode) {
  const video = videoNode;
  let count = 1;
  let program;
  video.oncanplay = () => {
    if (program && count > 1) {
      // 会有概率出现canvas静止不动的状态，经查是由于canvas textures.animated字段为false, 下述赋值代码为兼容此问题
      program.uniforms.values.uSampler.texture.animated = true;
      program.textures.animated = true;
      program.textures.values.uSampler.animated = true;
      program.animated = true;
      console.log(program.textures.animated, 'program.textures.animated');
      return;
    }
    const { videoWidth, videoHeight } = videoNode;
    // console.log('videoWidth', videoWidth, canvasNode.width);
    // oncanplay会掉用多次，避免重复渲染
    count = count + 1;
    const options = {
      fragmentString: frag,
      alpha: true,
      antialias: true,
      mode: 'flat',
    };
    canvasNode.width = videoWidth;
    canvasNode.height = videoHeight;
    program = new Canvas(canvasNode, options);
    program.setUniform('uSampler', videoNode);
    program.play();
  }
}

export function createTag(pos, size, dom) {
  // video 节点
  const videoNode = document.createElement('video');
  videoNode.autoplay = true;
  videoNode.muted = true;
  videoNode.id = '#rtc_media_player_1';
  videoNode.playsinline = true;
  videoNode.setAttribute('webkit-playsinline', 'true');
  videoNode.setAttribute('playsinline', 'true');

  videoNode.setAttribute("style", "width: 100%; height: 100%; visibility: hidden; position: absolute; top: 0; left: 0;");

  // canvas节点
  const canvasNode = document.createElement('canvas');
  canvasNode.setAttribute("style", "width: 100%; position: absolute; top: 0; left: 0;");

  // 外层包裹节点
  let wrapperNode = null;
  if (dom) {
    wrapperNode = dom;
    wrapperNode.appendChild(videoNode);
    wrapperNode.appendChild(canvasNode);
  } else {
    wrapperNode = document.createElement('div');
    wrapperNode.setAttribute("style", `width: ${size[0]}; height: ${size[1]}; position: fixed; top: ${pos[1]}; left: ${pos[0]}; z-index: 66`);
    wrapperNode.appendChild(videoNode);
    wrapperNode.appendChild(canvasNode);
    document.body.appendChild(wrapperNode);
  }
  return {
    videoNode,
    canvasNode,
  }
}

export function createMedia(dom, config) {
  const { mediaType, mediaUrl } = config;
  // video 节点
  let elem;
  if (mediaType === 'video') {
    elem = document.createElement('video')
    elem.autoplay = true;
    elem.muted = true;
    elem.playsinline = true;
    elem.src = mediaUrl;
    elem.setAttribute('webkit-playsinline', 'true');
    elem.setAttribute('playsinline', 'true');
    elem.setAttribute("style", "width: 100%;");
  } else if (mediaType === 'image') {
    elem = document.createElement('img');
    elem.src = mediaUrl;
    elem.setAttribute("style", "width: 100%;");
  } else if (mediaType === 'audio') {
    elem = document.createElement('audio');
    elem.src = mediaUrl;
    // elem.setAttribute("style", "width: 100%;");
  } else if (mediaType === 'text') {
    elem = document.createElement('div')
    elem.innerHTML = mediaUrl;
    elem.setAttribute("style", "width: 100%; height: 100%;");
  }



  // 外层包裹节点
  let wrapperNode = null;
  if (dom) {
    wrapperNode = dom;
    wrapperNode.innerHTML = "";
    if (elem) {
      wrapperNode.appendChild(elem);
    }
  }
  return {
    elem,
  }
}


export function handleSei(dataStr, event) {
  try {
    let seiData = JSON.parse(dataStr);
    const typeArr = seiData.map(item => item.sei_type);
    // console.log(typeArr, '解析到的sei_type信息')
    if (typeArr.includes('speak_start')) {
      event('speakStart')
    } else if (typeArr.includes('speak_end')) {
      event('speakEnd')
    }
  } catch (e) {
    event('error', { code: '-2222', msg: `sei解析失败 ${dataStr}` })
  }
}

export function throttle(callback, delay, immediate = true) {
  var timer, context, iNow, firstTime = +new Date(), args = [];
  return function () {
    clearTimeout(timer);
    context = this;
    iNow = +new Date();
    args = Array.prototype.slice.call(arguments);
    // 判断是否是第一次执行
    if (immediate) {
      immediate = false;
      callback.apply(context, args);
    } else {
      // 第二次执行的时候判断时间差
      if (iNow - firstTime > delay) {
        firstTime = iNow;
        callback.apply(context, args);
      } else {
        // 判断是否是最后一次执行
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, delay)
      }
    }
  }
}

export function isSecurity() {
  if (location.protocol === 'http:') {
    return false;
  }
  return true;
}

/** 判断浏览器兼容性 */
export function canRun() {
  const noFn = typeof RTCPeerConnection === 'undefined';
  console.log('noFn', noFn);
  
  return !noFn;
}

/** 通过webrtcurl 只播放 */
export const onlyPlay = (webRtcUrl, videoNode, instance, event) => {
  console.log('@@@@@@@@@@@@@', webRtcUrl);
  let sdkInstance = instance.sdkInstance;
  if (sdkInstance) {
      sdkInstance.close(); // 如果有上一个连接没关闭，则关闭
  }
  //
  instance.sdkInstance = new SrsRtcPlayerAsync();
  
  sdkInstance = instance.sdkInstance;
  sdkInstance.onaddstream = function (event) {
      console.log('onaddstream', event);
      videoNode.srcObject = event;
  };
  sdkInstance.onseimsg = function (dataStr) {
      console.log('onseimsg', dataStr);
      
      handleSei(dataStr, event);
  };
  sdkInstance.play(webRtcUrl).then(function (session) {
      console.log('session', session);
      
  }).catch(function (reason) {
      sdkInstance.close();
      console.error(reason);
  });
};

// 如果多个相同触发在间隔内，只触发第一次
export const onlyOnce = (func, delay) => {
  let canTrigger = true
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      canTrigger = true
    }, delay);

    if (canTrigger) {
      func(...args);
      canTrigger = false
    }
  };
};