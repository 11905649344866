

// 销毁
function stopVideo(instance) {
  if (!instance.wsInstance) return;
  instance.wsInstance.close();
}

// 说话 extra
function speak(str, extra) {
  const { streamId, instance, videoNode, vhostToken } = this;
  let _config = {};
  // 校验
  if (!!extra && typeof extra !== 'object') {
    console.error('speak第二个参数错误，请传入json对象')
  } else if (!!extra) {
    _config = extra;
  }
  if (!instance.wsInstance) return;
  // 兼容，有的浏览器不会自动播放
  videoNode.play();
  instance.wsInstance.send(JSON.stringify({
    vhostToken, // 用户认证
    uuid: "speak", // 唯一，不同消息区分
    msgType: 12, // 请求打通连接
    data: {
      data: {
        ..._config,
        "message_type": "speak",
        "text": str,  //讲话内容
        "stream_id": streamId,
      }
    }
  }));
}

// 询问虚拟人问题
function askAboutHuman(str, extra) {
  let _config = {};
  // 校验
  if (!!extra && typeof extra !== 'object') {
    console.error('speak第二个参数错误，请传入json对象')
  } else if (!!extra) {
    _config = extra;
  }
  const { instance, vhostToken } = this;
  // // 校验
  if (!instance.wsInstance) return;
  instance.wsInstance.send(JSON.stringify({
    vhostToken, // 用户认证
    uuid: "answer", // 唯一，不同消息区分
    msgType: 12, // 请求打通连接
    data: {
      data: {
        ..._config,
        "message_type": "answer",
        "text": str,
      }
    }
  }));
}

// 终端虚拟人讲话能力
function stopSepak() {
  const { instance, vhostToken } = this;
  // 校验
  if (!instance.wsInstance) return;
  instance.wsInstance.send(JSON.stringify({
    vhostToken, // 用户认证
    uuid: "stop-speak", // 唯一，不同消息区分
    msgType: 12, // 请求打通连接
    data: {
      data: {
        "message_type": "interrupt",
      }
    }
  }));
}



function autoPlayAudio(videoNode) {
  // 静音，浏览器只有静音视频才能自动播放
  function handleMuted() {
    videoNode.muted = false;
  }
  // 任意点击播放声音，因为video标签不支持自动播放声音
  document.addEventListener('click', handleMuted);
}

function destroy() {
  const { dom, videoNode, canvasNode, instance } = this;
  stopVideo(instance);
  // 如果传入了dom父节点，只要删除本身；否则连创建的父节点一起删除
  if (dom) {
    dom.removeChild(videoNode);
    dom.removeChild(canvasNode);
  } else {
    videoNode && videoNode.parentNode.remove();
  }
  // document.removeEventListener('click', handleMuted);
  instance.wsInstance = null;
  instance.sdkInstance = null;
}

export {
  // handleMuted,
  speak,
  destroy,
  autoPlayAudio,
  stopSepak,
  askAboutHuman,
}