import { postData } from './util';
export function SrsRtcPlayerAsync() {
    var self = {};
    self.play = async function (url) {
        var conf = self.__internal.prepareUrl(url);
        self.pc.addTransceiver("audio", { direction: "recvonly" });
        self.pc.addTransceiver("video", { direction: "recvonly" });

        var offer = await self.pc.createOffer();
        await self.pc.setLocalDescription(offer);
        var session = await new Promise(function (resolve, reject) {
            var data = {
                api: conf.apiUrl, streamurl: conf.streamUrl, clientip: null, sdp: offer.sdp
            };
            postData(conf.apiUrl, data).then(data => {
                if (data.code) {
                    reject(data); return;
                }
                resolve(data);
            }).catch(error => {
                reject(error);
            })
        });
        await self.pc.setRemoteDescription(
            new RTCSessionDescription({ type: 'answer', sdp: session.sdp })
        );
        return session;
    };

    self.close = function () {
        self.pc.close();
    };

    // The callback when got remote stream.
    self.onaddstream = function (event) { };
    self.onseimsg = function (data) { };
    self.__internal = {
        defaultPath: '/rtc/v1/play/',
        prepareUrl: function (webrtcUrl) {
            var urlObject = self.__internal.parse(webrtcUrl);
            var schema = urlObject.user_query.schema;
            schema = schema ? schema + ':' : window.location.protocol;

            var port = urlObject.port || 1985;
            if (schema === 'https:') {
                port = urlObject.port || 443;
            }
            var api = urlObject.user_query.play || self.__internal.defaultPath;
            if (api.lastIndexOf('/') !== api.length - 1) {
                api += '/';
            }

            apiUrl = schema + '//' + urlObject.server + ':' + port + api;
            for (var key in urlObject.user_query) {
                if (key !== 'api' && key !== 'play') {
                    apiUrl += '&' + key + '=' + urlObject.user_query[key];
                }
            }
            var apiUrl = apiUrl.replace(api + '&', api + '?');

            var streamUrl = urlObject.url;

            return { apiUrl: apiUrl, streamUrl: streamUrl, schema: schema, urlObject: urlObject, port: port };
        },
        parse: function (url) {
            var a = document.createElement("a");
            let schema = '';
            a.href = url.replace("webrtc://", "http://")

            var vhost = a.hostname;  // 当前网站的域名或ip
            var app = a.pathname.substr(1, a.pathname.lastIndexOf("/") - 1); // 域名 / 后的第一个参数
            var stream = a.pathname.substr(a.pathname.lastIndexOf("/") + 1);
            app = app.replace("...vhost...", "?vhost=");



            if (a.hostname === vhost) {
                var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
                if (re.test(a.hostname)) {
                    vhost = "__defaultVhost__";
                }
            }

            if (url.indexOf("://") > 0) {
                schema = url.substr(0, url.indexOf("://"));
            }


            var port = a.port;
            if (!port) {
                if (schema === 'http') {
                    port = 80;
                } else if (schema === 'https') {
                    port = 443;
                } else if (schema === 'rtmp') {
                    port = 1935;
                }
            }
            var ret = {
                url: url,
                schema: schema,
                server: a.hostname, port: port,
                vhost: vhost, app: app, stream: stream
            };
            self.__internal.fill_query(a.search, ret);

            if (!ret.port) {
                if (schema === 'webrtc' || schema === 'rtc') {
                    if (ret.user_query.schema === 'https') {
                        ret.port = 443;
                    } else if (window.location.href.indexOf('https://') === 0) {
                        ret.port = 443;
                    } else {
                        ret.port = 1985;
                    }
                }
            }

            return ret;
        },
        fill_query: function (query_string, obj) {
            obj.user_query = {};
        }
    };

    self.pc = new RTCPeerConnection({
        sdpSemantics: 'unified-plan'
    });
    self.pc.ontrack = function (event) {
        if (self.onaddstream) {
            self.onaddstream(event.streams[0]);
            // console.log('event.streams[0]', event.streams[0]);
            // 这边只对 video 处理，不管audio
            if (event && event.track && event.track.kind === 'video') {
                const recorder = new MediaRecorder(event.streams[0]);
                recorder.ondataavailable = async (e) => {
                    let sei = await e.data.slice(0, 400).text();
                    // TODO sei_type写死了
                    if (sei.indexOf('sei_type') !== -1) {
                        parseAVCVideoData(e.data, self.onseimsg);
                    }
                }
                recorder.start(0);
            }

        }
    };
    return self;
}


function parseAVCVideoData(blob, callback) {
    var reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = function () {
        const arrayBuffer = this.result;
        let v = new DataView(arrayBuffer);
        //此处代码需要先了解 h264视频流 sei格式；；以及 js 操作 arrayBuffer 相关api
        for (let i = 0; i < v.byteLength - 2; i++) {
            let offset = i;
            let unitType = v.getUint8(offset) & 0x1F;
            if (unitType === 6) {
                let unitTag = v.getUint8(++offset)
                while (unitTag !== 0) {
                    const payloadContentLenght = v.getUint8(++offset);
                    if (unitTag === 5) {
                        // 16位uuid忽略
                        offset += 16
                        let dataContent = new Uint8Array(arrayBuffer, ++offset, payloadContentLenght - 16 - 1);

                        let st = ''
                        if (dataContent) {
                            dataContent.map(item => {
                                // console.log(item)
                                st += String.fromCharCode(item)
                            })
                            callback(st);
                            // console.log('sei', st)
                        }
                        return;
                    } else {
                        offset += payloadContentLenght;
                        unitTag = v.getUint8(++offset)
                    }
                }
            }
        }
    }

}


